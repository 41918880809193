<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="settingsData"
      sort-by="name"
      class="elevation-1"
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Platform</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="openCreateDialog">
            New Platform
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:no-data> No Data </template>

       <template v-slot:item.actions="{ item }">
    
      <v-icon
        small
        @click="openDeleteDialog(item)"
        class="mr-2"
      >
        mdi-delete
      </v-icon>
        
     
    </template>
    </v-data-table>

    <v-dialog v-model="createDialog" max-width="600">
      <v-toolbar color="primary" dark
        >Add New

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card class="pa-5" :disabled="loading">
        <v-text-field label="Setting Name" v-model="settingName"></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="createDialog = false">
            Cancel
          </v-btn>

          <v-btn class="primary" text @click="createSetting"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

     <v-dialog v-model="deleteDialog" max-width="600">
      <v-toolbar color="primary" dark
        >Are you sure?

        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          bottom
          color="white"
        ></v-progress-linear>
      </v-toolbar>
      <v-card class="pa-5" :disabled="loading">
        

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDialog = false">
            Cancel
          </v-btn>

          <v-btn class="primary" text @click="deleteSetting"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      createDialog: false,
      deleteDialog: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Name", value: "name" },
        { text: "Created By", value: "createdBy" },
        {
          text: "Action",
          // Row to replace the original template
          value: "actions",
        },
      ],
      settingsData: [],
      settingName: "",
      loading: false,
      currentItem: {}
    };
  },
  methods: {
    openCreateDialog() {
      this.createDialog = true;
      this.settingName = "";
    },
    openDeleteDialog(item){
        this.deleteDialog = true;
        this.currentItem = item;

    },
    handleClick() {},

    createSetting() {
      this.loading = true;
      this.$General
        .createPlatform({ name: this.settingName })
        .then((data) => {
          this.loading = false;
          this.settingName = "";
          this.createDialog = false;
          this.fetchData();
        })
        .catch((error) => {
          this.loading = false;
          });
    },
    deleteSetting() {
        this.$General.deletePlatform(this.currentItem.id).then(data=>{
            this.fetchData();
            this.deleteDialog = false;
            this.currentItem = {};
        }).catch(error=>{
            console.log(error);
        })
    },
    fetchData() {
      this.$General
        .getPlatform()
        .then((data) => {
          this.settingsData = data.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>