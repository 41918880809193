



<template>
  <v-container>
    <v-card color="basil">
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab> Style </v-tab>
        <v-tab> Platform </v-tab>
        <v-tab> Project </v-tab>
      </v-tabs>
    </v-card>
    <v-tabs-items v-model="tab">
      <v-tab-item style="background: transparent !important">
        <AppStyle />
      </v-tab-item>
      <v-tab-item>
        <Platform />
      </v-tab-item>

      <v-tab-item>
        <Project />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AppStyle from "./Style.vue";
import Platform from "./Platform.vue";
import Project from "./Project.vue";
export default {
  data() {
    return {
      tab: 0,
    };
  },
  components: {
    AppStyle,
    Platform,
    Project,
  },
};
</script>